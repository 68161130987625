@import '../common/variables';

.be-modal.be-modal-share {
    .bce-shared-access-select {
        height: 27px;
        line-height: 15px;
    }

    .be-modal-content {
        position: relative;

        input[type='text'] {
            width: 296px;
            text-overflow: ellipsis;
            border-right: 0 none;
            border-radius: 3px 0 0 3px;

            .be-is-small & {
                width: 237px;
            }
        }
    }

    .be-modal-button-copy {
        height: 36px;
        margin: 0 0 0 -5px;
        padding: 0 10px;
        font-weight: bold;
        font-size: 10px;
        line-height: 34px;
        letter-spacing: 1px;
        text-transform: uppercase;
        border-radius: 0 3px 3px 0;
    }

    .be-modal-btns {
        align-items: center;
        justify-content: space-between;
    }
}
