@import '../../common/variables';

.bcpr-PreviewHeader {
    display: flex;
    flex: 0 0 60px;
    padding: 0;
    font-size: 13px;

    .bcpr-PreviewHeader-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        background-color: $bdl-gray-02;
        border-bottom: 1px solid $bdl-gray-10;
    }

    // Box Annotations selector
    .bp-header {
        flex: 1;
        height: 60px;
    }

    // Box Annotations overrides
    button.ba-btn-annotate-point-exit,
    button.ba-btn-annotate-draw-cancel {
        color: $bdl-gray-10;
        background-color: $bdl-gray;
        border: solid $bdl-gray-10 1px;

        &:hover {
            background-color: $black;
        }
    }

    button.ba-btn-annotate-draw-post {
        color: $bdl-gray;
        background-color: $bdl-gray-10;

        &:hover {
            background-color: $white;
        }
    }

    .ba-point-post-cancel-container,
    .ba-draw-post-cancel-container {
        position: absolute;
        right: 0;
        margin-right: 20px;

        button {
            height: 32px;
            margin: 8px;
        }
    }

    .ba-draw-undo-redo-container {
        margin: 0 auto;

        .ba-btn-annotate-draw-redo,
        .ba-btn-annotate-draw-undo,
        button.ba-btn-annotate-draw-redo,
        button.ba-btn-annotate-draw-undo,
        .is-disabled.ba-btn-annotate-draw-redo,
        .is-disabled.ba-btn-annotate-draw-undo {
            margin: 5px;
            background: none;
            border: none;

            svg {
                vertical-align: middle;
                fill: $bdl-gray-10;
            }
        }
    }
}

.bcpr-PreviewHeader--basic {
    .bcpr-PreviewHeader-content {
        color: $bdl-gray-02;
        background: $bdl-gray;
        border-bottom: 1px solid $bdl-gray;
    }
}

.bcpr-PreviewHeader-controls {
    display: flex;

    .be & .bcpr-bcow-btn {
        margin: 0 10px 0 0;
        padding: 0 5px;

        &:active,
        &:focus,
        &:hover {
            background: none;
        }
    }
}

.bcpr-PreviewHeader-button.btn-plain {
    display: flex;
    align-items: center;
    color: $bdl-gray-02;

    &,
    &:hover,
    &:active {
        padding: 5px 10px;
    }

    &:focus,
    &:hover {
        color: $bdl-gray-10;

        .fill-color {
            fill: $bdl-gray-62;
        }
    }
}

.bcpr-PreviewHeader-button-close.btn-plain {
    font-weight: bold;

    .icon-close {
        margin: -3px; // Close icon has more padding than print/download
    }
}
