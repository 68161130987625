@import '../common/variables';

.bcpr {
    display: flex;
    flex: 1;
    flex-direction: column;

    .bcpr-body {
        display: flex;
        flex: 1;
    }

    .bcpr-container {
        position: relative;
        display: flex;
        flex: 1;
    }

    .bcpr-content {
        flex: 1;
    }

    .bp-navigate {
        /* stylelint-disable declaration-no-important */
        display: none !important;
        /* stylelint-enable declaration-no-important */
    }

    .bcpr-navigate-left,
    .bcpr-navigate-right {
        position: absolute;
        top: 50%;
        display: block;
        width: 50px;
        height: 64px;
        transform: translateY(-50%);
        opacity: 0;
        transition: opacity .5s ease;

        &:focus,
        &:hover {
            opacity: 1;
        }
    }

    .bcpr-navigate-right {
        right: 0;
    }

    .bcpr-navigate-left {
        left: 0;
        transition: opacity .5s ease, left .3s cubic-bezier(.4, 0, .2, 1);
    }

    .bcpr-nav-is-visible {
        .bcpr-navigate-left,
        .bcpr-navigate-right {
            opacity: 1;
        }
    }

    &.be-is-small {
        .bcpr-body {
            position: relative;
        }

        .bcs.bcs-is-open {
            position: absolute;
            right: 0;
        }
    }

    .bcpr-loading-wrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        background-color: $bdl-gray-05;

        .bcpr-loaded & {
            display: none;
        }
    }
}

.bcpr.bcpr-thumbnails-open .bcpr-navigate-left {
    left: 226px;
}
