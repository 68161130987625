@import '../../styles/variables';
@import 'pikaday';

/**************************************
 * Date Picker
 **************************************/

.date-picker-wrapper {
    position: relative;
    margin: 0 0 20px;

    button:focus {
        outline: none;
    }

    .date-picker-icon-holder {
        position: relative;
        display: inline-block;
    }

    .date-picker-description {
        display: block;
        color: $bdl-gray-62;
    }

    .date-picker-input {
        margin-top: 5px;
        margin-bottom: 0;
        padding-right: 30px;
        text-overflow: ellipsis;
    }

    .date-picker-input:focus ~ .tooltip,
    .date-picker-unix-time-input {
        display: none;
    }

    .date-picker-open-btn {
        position: absolute;
        right: 7px;
        bottom: 10px;
        display: inline-block;
        height: 17px;
    }

    .date-picker-clear-btn,
    .date-picker-icon-alert {
        position: absolute;
        right: 30px;
        bottom: 10px;
        display: inline-block;

        span {
            display: inline-block;
        }
    }

    .date-picker-clear-btn,
    .date-picker-clear-btn:active,
    .date-picker-clear-btn:focus,
    .date-picker-clear-btn:hover,
    .date-picker-clear-btn:active:focus,
    .date-picker-clear-btn:active:hover,
    .date-picker-clear-btn span {
        height: 14px;
    }

    .date-picker-clear-btn {
        path {
            transition: fill linear .1s;
            fill: $bdl-gray-40;
        }
    }

    > .date-picker-clear-btn:hover,
    > .date-picker-clear-btn:focus {
        path {
            fill: $bdl-gray-80;
            path: $bdl-gray-80;
        }
    }

    > .date-picker-open-btn:disabled {
        cursor: default;
    }

    > .date-picker-open-btn:hover:enabled,
    > .date-picker-open-btn:focus:enabled {
        .fill-color {
            fill: $bdl-gray-80;
        }
    }

    .date-picker-icon-alert {
        path {
            fill: $bdl-watermelon-red;
        }
    }

    &.show-clear-btn.show-error {
        .date-picker-clear-btn,
        .date-picker-clear-btn:hover,
        .date-picker-clear-btn:active {
            right: 25px;
        }

        .date-picker-input {
            padding-right: 50px;
        }
    }
}
