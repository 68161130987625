@import '../../../styles/variables';

.metadata-instance-editor-field-date {
    .date-picker-icon-holder {
        width: 100%;

        .date-picker-input {
            width: 100%;
        }
    }
}
