@import '../../styles/variables';

/**************************************
 * Text Input
 **************************************/

.text-input-container {
    position: relative;
    margin: 0 0 20px;

    .text-input-loading {
        display: inline-block;
        margin-left: -21px;
    }

    .text-input-verified {
        display: inline-block;
        margin-bottom: -2px;
        margin-left: -24px;
    }

    .label {
        word-wrap: break-word;
    }

    .text-input-description {
        display: block;
        color: $bdl-gray-62;
        word-wrap: break-word;
    }

    input {
        height: 32px;
        margin-top: 5px;
        font-family: inherit;

        &[type='number'] {
            -moz-appearance: textfield;
            -ms-appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                margin: 0;
                -webkit-appearance: none;
            }
        }
    }

    &.is-required,
    &.show-error {
        input {
            border-color: $bdl-watermelon-red;
        }
    }
}
