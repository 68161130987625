@import '../../styles/variables';

.datalist-item {
    padding: 5px 35px 5px 15px;

    &.is-active {
        background-color: fade-out($bdl-gray, .95);
        cursor: pointer;
    }
}
