@import '../../../styles/variables';

.metadata-instance-editor-field-read-only {
    margin: 0 0 20px;
    word-wrap: break-word;

    &:last-child {
        margin-bottom: 0;
    }

    .metadata-instance-editor-field-custom:not(:last-child) & {
        margin-bottom: 20px;
    }

    dt {
        color: $bdl-gray-62;
    }

    dd {
        line-height: 16px;
    }

    .metadata-instance-editor-field-read-only-desc {
        color: $bdl-gray-62;
    }
}
