@import '../../styles/variables';

/**************************************
 * Pikaday
 **************************************/

.pika-single {
    @include common-typography;
    @include box-sizing;

    position: relative;
    z-index: 9999;
    display: block;
    color: $bdl-gray-80;
    background: $white;
    border: 1px solid $bdl-gray-30;
    border-bottom-color: $bdl-gray-50;

    &.is-hidden {
        display: none;
    }

    &.is-bound {
        position: absolute;
        margin-top: 5px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .1);
    }
}

.pika-lendar {
    padding: 10px;
}

.pika-title {
    position: relative;
    margin-bottom: 10px;
    text-align: center;

    select {
        position: absolute;
        top: 5px;
        left: 0;
        z-index: 9998;
        margin: 0;
        cursor: pointer;
        opacity: 0;
    }
}

.pika-label {
    position: relative;
    z-index: 9999;
    display: inline-block;
    padding: 5px 15px 5px 5px;
    overflow: hidden;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    background-color: $white;

    &::after {
        position: absolute;
        top: 13px;
        right: 4px;
        border-top: 4px solid $bdl-gray-80;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        content: '';
    }
}

.pika-button,
.pika-next,
.pika-prev {
    background: none;
    border: 0;
    outline: none;
}

.pika-prev,
.pika-next {
    display: block;
    width: 20px;
    height: 30px;
    overflow: hidden;
    white-space: nowrap;

    /* hide text using text-indent trick. */

    /* negative because firefox indents pseudo-elements when text-indent is positive. 999 because i18n consideration */
    text-indent: -999px;
    cursor: pointer;

    &.is-disabled {
        cursor: default;
        opacity: .2;
    }
}

.pika-prev {
    float: left;

    &::before {
        position: absolute;
        top: 10px;
        left: 10px;
        border-top: 4px solid transparent;
        border-right: 4px solid $bdl-gray-80;
        border-bottom: 4px solid transparent;
        content: '';
    }
}

.pika-next {
    float: right;

    &::after {
        position: absolute;
        top: 10px;
        right: 10px;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 4px solid $bdl-gray-80;
        border-radius: 2px;
        content: '';
    }
}

.pika-select {
    display: inline-block;
}

.pika-table {
    abbr {
        font-weight: bold;
    }

    th {
        width: 32px;
        color: $primary-color;
        font-size: 13px;
        text-align: center;
    }

    th,
    td {
        width: 14.285714285714286%;
    }

    > thead > tr {
        height: 25px;
        vertical-align: top;
    }

    > tbody > tr {
        height: 33px;
    }
}

.pika-button {
    width: 30px;
    height: 30px;
    margin: 3px;
    font-size: 13px;
    text-align: center;
}

.is-today .pika-button {
    font-weight: bold;
}

.pika-button:hover,
.is-selected .pika-button {
    color: $white;
    font-weight: bold;
    background-color: $primary-color;
    border-radius: 3px;
    cursor: pointer;
}

.is-disabled .pika-button,
.is-outside-current-month .pika-button {
    cursor: default;
    opacity: .3;
    pointer-events: none;
}
