@import '../../styles/variables';

/**************************************
 * Label
 **************************************/

.label {
    display: block;
    color: $bdl-gray-62;
    font-weight: bold;

    .label-optional {
        padding-left: 3px;
    }

    .tooltip-icon-container {
        padding-left: 3px;

        .tooltip-icon {
            position: relative;
            top: 3px;
        }
    }
}
